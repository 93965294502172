.pageRefreshWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  width: 100%;
  color: white;;
  height: 60px;
  z-index: 9999999;
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  .seconds {
    color: red;
    font-weight: 600;
    font-size: 1.5rem;
  }
  .refreshNow {
    margin-left: 2rem;
    height: 40px;
    width: 180px;
    border-radius: 15px;
    border: none;
    background-color: rgb(63, 111, 255);
    cursor: pointer;
    color: white;
  }
}