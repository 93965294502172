.visitSystemSettingsWrapper {
  * {
    transition: 0.4s!important;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;

  .systemSettingsWrapperCard {
    width: 800px;
    // height: 400px;
    padding: 80px 0;
    border-radius: 6px;
    background-color: white;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.082);
    display: flex;
    justify-content: center;
    align-items: center;

    .settingOptions {
      width: 450px;

      .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgb(173, 173, 173);

        .label {
          font-weight: 400;
          font-size: 1.2rem;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            margin-left: 18px;
            border-radius: 15px;
            cursor: pointer;
            border: none;
            background-color: rgb(255, 196, 2);
            font-size: 0.7rem;
            color: white;
            padding: 3px 18px;
            &:disabled {
            background-color: rgb(221, 221, 221);
              cursor: not-allowed;
            }
          }
        }

        &:last-child {
          border-bottom: 0px solid rgb(173, 173, 173);
        }
      }
    }
  }

  @supports(-webkit-appearance: none) or (-moz-appearance: none) {

    input[type='checkbox'],
    input[type='radio'] {
      --active: #275EFE;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #275EFE;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;

      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }

      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }

      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;

        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }

        &+label {
          cursor: not-allowed;
        }
      }

      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }

      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }

      &:not(.switch) {
        width: 21px;

        &:after {
          opacity: var(--o, 0);
        }

        &:checked {
          --o: 1;
        }
      }

      &+label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }

    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 7px;

        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }

        &:checked {
          --r: 43deg;
        }
      }

      &.switch {
        width: 38px;
        border-radius: 11px;

        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }

        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }

        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
    }

    input[type='radio'] {
      border-radius: 50%;

      &:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, .7));
      }

      &:checked {
        --s: .5;
      }
    }
  }
  .manageVisitOptions {
    margin-top: 5rem;
    width: 100%;
    height: 60px;
    border: 1px solid rgb(0, 127, 253);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.12);
    button {
      background-color: rgb(52, 185, 0);
      border-radius: 10px;
      border: none;
      padding: 10px 40px;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);
      cursor: pointer;
      color: white;
      &:hover {
        background-color: rgb(41, 146, 0);  
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.006);
      }
    }
  }
}


.visitSettingsHeader {
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: -2rem;
}