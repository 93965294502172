.manageVisitReasonsModal {
  * {
    transition: 0.4s;
    ;
  }

  .manageVisitReasonsModalInnerWrapper {
    position: relative;
    height: 100%;

    .modalBody {
      padding: 0 100px;
      margin-top: 1.8rem;

      .header {
        width: 100%;

        h3 {
          width: 100%;
          text-align: center;
        }
      }

      .optionsWrapper {

        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.333);
        border-radius: 4px;
        padding-left: 0px;
        height: 460px;

        .optionsRow {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.326);

          .namePart {
            flex: 8;
            padding: 9px 20px;

            input {
              width: 80%;
              background-color: rgba(233, 233, 233, 0.312);
              border: 1px solid rgb(194, 194, 194);
            }

            &.editable {
              cursor: text;
            }
          }

          .actionPart {
            flex: 4;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .iconBtn {
              width: 25px;
              height: 25px;
              background-color: blue;
              color: white;
              display: flex;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              border-radius: 60px;
              margin-right: 25px;

              &.saveBtn {
                background-color: rgb(43, 199, 0);
                width: 120px;
                i {
                  margin-right: 15px;
                }
              }

              &.editBtn {
                background-color: rgb(255, 124, 1);
              }

              &.deleteBtn {
                background-color: rgb(250, 6, 6);
              }

              &.cancelBtn {
                background-color: rgb(250, 6, 6);
              }
            }
          }

          &.headerRow {
            border-bottom: 1px solid rgba(0, 0, 0, 0.326);
            background-color: rgb(238, 238, 238);
            padding-left: -5px;
          }
        }

        .optionsInnerWrapper {
          height: 410px;
          width: 100%;
          overflow-y: scroll;
          /* width */

        }
      }

      .createNewReason {
        width: 100%;
        form {
          margin-top: 4rem;
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);
          width: 100%;
          border: 1px solid rgb(0, 132, 248);
          border-radius: 4px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
  
        }

        input {
          padding-left: 25px;
          flex: 10;
          border: none;
        }

        button {
          border: none;
          cursor: pointer;
          background-color: rgb(0, 115, 230);
          color: white;
          height: 100%;
          flex: 3;

          &.disabledBtn {
            background-color: rgb(167, 203, 255);
            cursor: not-allowed;
          }
        }
      }
    }

    .bottomActionBar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      // background-color: slategray;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .saveBtn {
        height: 50px;
        flex: 4;
        background-color: rgb(52, 185, 0);
        border: none;
        padding: 5px 60px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          flex: 8;
        }
      }

      .cancelBtn {
        height: 50px;
        flex: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(238, 0, 0);
        border: none;
        padding: 5px 30px;
        cursor: pointer;
        color: rgb(255, 255, 255);

        &:hover {
          flex: 8;
        }
      }
    }
  }
  .errorDisplay {
    width: 100%;
    text-align: center;
    color: red;
    font-weight: 600;
    margin-top: 2.5rem;
  }
  .noOptionWrapper {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: rgb(182, 182, 182);
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}