#modalFormWrapper {
  padding: 10px 35px;


.usernameError {
  margin-top: 5px!important;
  margin-bottom: -5px!important;
}

  h3 {
    margin-bottom: 20px;
  }

  position: relative;

  .crossWrapper {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .promptFormWrapper {
    .promptFormRow {
      display: flex;

      .promptInputWrapper {
        flex: 1;
        padding: 0 10px;
      }
    }

    .formErrorText {
      width: 100%;
      margin-top: -16px;
      font-size: 0.6rem;
      margin-bottom: 15px;
      color: red;
      font-weight: 400;
      height: 10px;
      text-align: left;
    }

  }

  .formActionWrapper {
    margin-top: 60px;
    display: flex;

    .saveButton {
      flex: 3;
    }

    .discardButton {
      flex: 1;
    }
  }
  .select-text {
    width: 465px!important;
  } 
  .select {
    width: 465px!important;
}
}

.userStatusWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -40px;

  &>div {
    margin-top: 6px;
    margin-left: 15px;
  }
}

.userLoader {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
