.faqWrapper {
  width: 100%;
  display: flex;
  padding: 3rem 10vw;
  justify-content: center;
  align-items: center;

  &>div {
    &>div {
      &:first-child {
        width: 70vw;
        overflow-x: scroll !important;
        cursor: pointer;
        /* width */
        &::-webkit-scrollbar {
          width: 50px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          width: 5px;
          background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      width: 70vw;
    }
  }

  .Mui-selected {
    background-color: #b02727 !important;
  }

  .accordianWrapper {
    background-color: white;
    padding: 15px 30px;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    color: #990000 !important;

    h4 {
      color: #990000 !important;
    }
  }

}