#clinicTable {
  .editButton {
    background-color: rgb(0, 110, 255);
    color: white;
    font-weight: 500;
    border: none;
    padding: 6px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.clinicTableAction {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;

  .newButton {
    background-color: rgb(1, 202, 85);
    color: white;
    font-weight: 500;
    border: none;
    padding: 8px 25px;
    border-radius: 4px;
    cursor: pointer;

  }


}

.manageUsersBtn {
  background-color: rgb(1, 202, 45);
  color: white;
  margin-left: 15px;
  font-weight: 500;
  border: none;
  padding: 6px 25px;
  border-radius: 4px;
  cursor: pointer;

}

.clinicTableAction {
  &.spaceBetween {
    justify-content: space-between;
    align-items: center;
    .addPatient {
      margin-top: 0!important;
    }
    
  }
  &.centered {
    justify-content: center;
  }
}