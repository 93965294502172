.patientQuickSearchPromptWrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(243, 243, 243);
  border-radius: 8px;
  position: relative;

  .filtersWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;

    .btn-group {
      border: 1px solid black;

      .btn-default {
        background-color: white;
        font-weight: 600;
        color: black;
        padding: 6px 30px 4px 30px;
      }
    }

    .dropdown-menu.show {
      li {
        transition: 0s !important;
        padding-top: 0px;
        padding-bottom: 0px;

        a {
          transition: 0s !important;
        }

        &:hover {
          transition: 0s !important;
          background-color: rgb(92, 92, 92) !important;

          a {
            transition: 0s !important;
            background-color: rgb(83, 83, 83) !important;
          }
        }
      }
    }
  }

  .dismissBtn {
    position: absolute;
    z-index: 101;
    top: 18px;
    right: 30px;
    font-size: 0.7rem;
    width: 25px;
    height: 25px;
    border-radius: 12px;
    background-color: white;
    color: red;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    transition: 0.3s;

    &:hover {
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0);
    }
  }

  .header {
    width: 100%;
    padding: 4rem 6rem;
    margin-bottom: -5rem;
    z-index: 55;

    .searchWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      // box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
      border: none;
      padding: 0;
      width: 100%;

      input {
        flex: 12;
        background-color: white;
        height: 100%;
        border: none;
        display: flex;
        padding-left: 2rem;
        justify-content: center;
        align-items: center;
        color: black;
        padding: 11px 25px;

        &::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          color: #a5a5a5;
        }

        &:-moz-placeholder,
        textarea:-moz-placeholder {
          color: #c3c3c3;
        }
      }

      .searchIcon {
        flex: 2;
        background-color: black;
        height: 100%;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        align-items: center;
        color: white;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          box-shadow: 4px 4px 6px rgba(0, 0, 0, 0);
        }
      }
    }
  }

  .searchContent {
    width: 100%;
    padding: 1rem 6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: calc(90% - 10rem);

    .noResultsWrapper {
      margin-top: 8rem;
    }

    .loaderWrapper {
      margin-top: 8rem;
    }

    .searchResults {
      width: 100%;

      // background-color: brown;
      .searchResultsFoundText {
        font-size: 0.8rem;
        font-weight: 600;
      }

      table {
        width: 100%;
        // background-color: blue;
      }

      table {
        width: 100%;
        table-layout: fixed;
      }

      .tbl-header {
        background-color: rgb(0, 0, 0);
      }

      .tbl-content {
        height: 45vh;

        overflow-x: auto;
        margin-top: 0px;
        // border: 1px solid rgba(255, 255, 255, 0.972);
      }

      th {
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
      }

      .actionCell {
        width: 160px;
      }

      td {
        padding: 15px;
        text-align: left;
        cursor: pointer;
        vertical-align: middle;
        font-weight: 300;
        font-size: 12px;
        color: rgb(25, 25, 25);
        border-bottom: solid 1px rgb(104, 104, 104);
        .visitSummary {
          p {
            word-wrap: break-word;
            font-weight: 600;
            span {
              font-weight: 400;
            }
          }
        }
      }

      tbody {
        tr {
          background-color: white;

          &:hover {
            background-color: rgb(20, 106, 255);

            td {
              color: white !important;
              font-weight: 600;
            }
          }
        }
      }



      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .patientView {
    background-color: rgb(243, 243, 243);
    padding: 4rem 6rem;
    height: 100%;
    width: 1100px;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;

    .loaderWrapper {
      margin-top: -8rem;
    }

    .backBar {
      width: 100%;
      margin-bottom: 4rem;

      .backBtn {
        cursor: pointer;
        width: 290px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.7rem;

        .text {
          margin-left: 15px;
          font-size: 1.2rem;
        }
      }
    }

    .visitHistoryLogs {
      margin-top: 2rem;

      // background-color: brown;
      .searchResultsFoundText {
        font-size: 0.8rem;
        font-weight: 600;
      }

      table {
        width: 100%;
        // background-color: blue;
      }

      table {
        width: 100%;
        table-layout: fixed;
      }

      .tbl-header {
        background-color: rgb(0, 0, 0);
      }

      .tbl-content {
        height: 40vh;

        

        overflow-x: auto;
        margin-top: 0px;
        // border: 1px solid rgba(255, 255, 255, 0.972);
      }

      th {
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
      }

      td {
        padding: 15px;
        text-align: left;
        cursor: pointer;
        vertical-align: middle;
        font-weight: 300;
        font-size: 12px;
        color: rgb(25, 25, 25);
        border-bottom: solid 1px rgb(104, 104, 104);

      }

      tbody {
        tr {
          background-color: white;

          &:hover {
            background-color: rgb(172, 172, 172);

          }
        }
      }



      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }

    .visitHistoryHeader {
      margin-top: 2.6rem;
      width: 100%;
    }
  }
  .logView {
    cursor: pointer;
    padding: 2px 20px;
    border: 1px solid black;
    background-color: white;
    color: black;
    border-radius: 60px;
  }
  .filtersWrapper {
    display: flex;
    .filters {
      display: flex;
      background-color: rgb(187, 187, 187);
      border-radius: 25px;
      cursor: pointer;
      div {
        padding: 3px 15px;
        font-size: 0.7rem;
        font-weight: 600;
        a {
          color: white!important;
        }
        &.selected {
          background-color: black;

        }
        &:last-of-type {
          &.selected {
            background-color: black;
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;
          }
      }
      &:first-of-type {
        &.selected {
          background-color: black;
          border-top-left-radius: 60px;
          border-bottom-left-radius: 60px;
        }
    }
      }
    }
  }
  .loaderWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.attendedByCell {
  width: 40%;
}

.dateCell {
  width: 12%;
}
