#personalDataInputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: white;

  #personalData {
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 45vw;

    @media only screen and (max-width: 640px) {
      width: 80vw;
    }

    h3 {
      color: black;

      @media only screen and (max-width: 640px) {
        font-size: 24px;
      }
    }

    p {
      margin-top: 3rem;
      width: 100%;
      text-align: start;
      color: red;
      font-size: 1.2rem;
    }
  }

  .nextBtn {
    margin-top: 5rem;
    font-size: 1.3rem;
    width: 400px;

    @media only screen and (max-width: 640px) {
      width: 300px;
    }
  }

  .userInput {
    font-size: 2rem;
  }

  .MuiInputBase-input {
    height: 50px !important;
    font-size: 30px !important;
  }

  .MuiInputLabel-formControl {
    top: 10px;
    color: rgb(36, 36, 36) !important;
    font-size: 29px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
    letter-spacing: unset;
  }

  .requried {
    color: red;
  }

  .datePickerWrapper {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.8rem!important;
      color: black!important;
      font-weight: 400;
      margin: 0!important;
    }
    input {
      font-size: 29px;
      text-align:  center;
      margin-left: 30px;
      width: 250px;
      border: none;
      border-bottom: 1px solid rgb(87, 87, 87);
    }
  }
}

.MuiPickersToolbar-toolbar {
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #0a0a0a!important;
}

.MuiPickersDay-daySelected {
  color: #fff;
  font-weight: 500;
  background-color: #0a0a0a!important;
}