#logoutNotiferWrapper {
  height: 100%;
  width: 100%;
  // background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .bottomLoader {
    width: 100%;
    height: 60px;
    margin-top: 19px;
    margin-left: -30px;
    // background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0);
    display: flex;
    // justify-content: center;
    align-items: center;

    .spinnerWrapper {
      flex: 4;
      display: flex;
      justify-content: flex-end;
      padding-right: 5px;
      align-items: center;

      .loader {
        border: 3px solid #5a5a5a;
        border-radius: 50%;
        border-top: 3px solid #ffffff;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 0.7s linear infinite;
        /* Safari */
        animation: spin 0.7s linear infinite;
      }
    }

    h3 {
      padding: 0px 0px 13px 25px;
      font-weight: 600;
      // font-size: 1.2rem;
      color: rgb(80, 80, 80);
      flex: 8
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}