.dashboardFilterWrapper {
  margin-bottom: 10px;
  .filterHeader {
    background-color: rgb(160, 0, 0);
    color: rgb(239, 255, 11);
    font-size: 1rem;
    padding: 5px 35px;
    text-align: center;
    font-weight: 600;
  }
  .filterBody {
    height: 23vh;
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
    padding: 5px 25px;
    .filterOptionWRapper {
      margin-top: 3px;
      font-size: 0.8rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      input {
        margin-right: 10px;
      }
    }
  }
}