.numberPadOuterWrapper {
  width: 280px;
  height: 350px;
  margin-top: 10rem;
  // background-color: #FFCC00;
  margin-left: 6rem;

  .numberPadInnerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .numRow {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .numKey {
        background-color: #990000;
        color: #FFCC00;
        font-size: 2.5rem;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        padding: 24px 28px;
        margin-bottom: 14px;
        border: none;
        cursor: pointer;

        &.deleteKey {
          color: #990000;
          background-color: #FFCC00;
          padding: 24px 22px;
          margin-right: 10px;

          &:active {
            box-shadow: 2px 2px 4px rgba(245, 245, 245, 0.2);
            background-color: #c74848;
            color: #990000;
            background-color: rgb(255, 255, 65);
          }
        }

        &:active {
          box-shadow: 2px 2px 4px rgba(245, 245, 245, 0.2);
          background-color: #c74848;
        }
      }

      &.lastRow {
        justify-content: flex-end;

        .numKey {
          margin-left: 14px;
        }
      }
    }
  }
}