.patientRegistrationPromptWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  button {
    cursor: pointer;
  }

  h3 {
    padding-left: 20px;
  }

  .modalHeader {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .dismissBtn {
      background-color: rgb(250, 44, 44);
      border: none;
      border-bottom-left-radius: 8px;
      color: white;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 4px 35px;
      cursor: pointer;
    }
  }

  padding: 30px 30px;

  .promptFormWrapper {
    .promptFormRow {
      display: flex;
      margin-bottom: 15px;

      .promptInputWrapper {
        flex: 1;
        padding: 0 10px;
        display: flex;

        .inputWrapper {
          width: 100%;
        }

        &>div {
          margin: 0px 10px;
        }

        &.dobInput {
          margin-top: 25px;

          .dobLabel {
            width: 25%;
          }

          input {
            width: 96%;
            margin: 0 10px;
            border: none;
            border-bottom: 1px solid rgb(192, 192, 192);
          }
        }

        &.phoneInput {
          margin-top: 25px;

          .phoneLabel {
            width: 35%;
          }

          input {
            font-size: 1.3rem;
            width: 96%;
            margin: 0 10px;
            border: none;
            border-bottom: 1px solid rgb(192, 192, 192);
          }
        }

        .errorText {
          color: red;
          height: 19px;
          font-size: 0.8rem;
          font-weight: 500;
          margin-bottom: -20px;
          margin-top: -10px;
        }
      }
    }

    .outerErrorText {
      color: red;
      height: 19px;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: -20px;
      margin-top: -10px;
      padding-left: 20px;
    }

    .reasonErrorText {
      color: red;
      height: 19px;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: 30px;
      margin-top: -30px;
      padding-left: 20px;
    }

    .formErrorText {
      width: 100%;
      margin-top: -16px;
      font-size: 0.6rem;
      margin-bottom: 15px;
      color: red;
      height: 19px;
      font-weight: 400;
      height: 10px;
      text-align: left;
    }

  }

  .visitReasonWrapper {
    padding: 30px 10px;

    h4 {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .reasonRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      max-height: 280px;
      overflow-y: scroll;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .reason {
        background-color: rgb(199, 199, 199);
        width: 240px;
        height: 35px;
        margin: 7px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 9px;

        &.selected {
          background-color: rgb(0, 174, 255);
          color: white;
        }
      }
    }
  }

  .bottomAction {
    margin-top: -2rem;
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 25px;
    left: 0;
    justify-content: center;
    align-items: center;

    .submitBtn {
      background-color: rgb(72, 219, 72);
      border: none;
      padding: 9px 50px;
      color: white;
      font-weight: 600;
      border-radius: 60px;
    }
  }

  .generalError {
    width: 100%;
    margin-top: 16px;
    margin-bottom: -16px;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    color: red;
    height: 19px;
    font-weight: 400;
    height: 10px;
    text-align: left;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid rgb(72, 219, 72);
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

