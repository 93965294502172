#labAssistantWrapper {
  max-width: 100%;
  padding: 8rem 6rem;
  min-height: 100vh;
  background-color: white;
  

  @media only screen and (max-width: 1200px) {
    padding: 8rem 2rem;
  }

  padding-left: 6rem!important;

  

}

.buttonsWrapper {
  display: flex;
  padding: 0 15px;
  .actions {
    border: none;
    background-color: royalblue;
    color: white;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 35px;
    box-shadow: 3px 3px 18px rgba(0,0,0,0.2);
  }
  .messages {
    margin-left: 18px;
    box-shadow: 3px 3px 18px rgba(0,0,0,0.2);
    border: none;
    cursor: pointer;
    background-color: rgb(65, 225, 100);
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    position: relative;
    .unreadNotification {
      position: absolute;
      bottom: -1px;
      box-shadow: 0px 0px 5px rgb(255, 0, 0);
      right: -1px;
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: red;
    }
  }
}

#labAssistantView {
  width: 100%;
  background-color: rgba(255, 255, 255, 0)!important;

  // padding-left: 2rem;
  @media only screen and (max-width: 760px) {
    padding: 10rem 1rem;
  }

  position: relative;

  .finCell {
    input {
      width: 150px;
      height: 40px;
      padding-left: 8px;
    }
  }

  .nameCell {
    p {
      width: 100px;
      font-size: 1.1rem;
      margin-bottom: 0;
    }
  }

  .actionBar {
    position: absolute;
    top: 0px;
    right: 0px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 25px;
      font-weight: 600;
      border-radius: 100px;
      border: none;
      background-color: black;
      box-shadow: 3px 3px 15px  rgba(0,0,0,0.2);
      color: white;
      cursor: pointer;
      &:hover {
        box-shadow: 3px 3px 15px  rgba(0, 0, 0, 0);  
      }
    }
  }

  padding-top: 2rem;
  table {
    width: 100%;

    @media only screen and (max-width: 768px) {
      width: 700px;
    }

    border-collapse: collapse;
    // margin: 50px auto;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.116);
    border-radius: 5px;
  }

  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: rgb(230, 230, 230);
  }

  th {
    background: #292929;
    color: white;
    font-weight: bold;
  }

  td,
  th {
    padding: 10px;
    // border-bottom: 1px solid rgb(22, 22, 22);
    text-align: left;
    font-size: 18px;
  }

  /* 
  Max width before this PARTICULAR table gets nasty
  This query will take effect for any screen smaller than 760px
  and also iPads specifically.
  */
  @media only screen and (max-width: 760px) {

    table {
      width: 100%;
    }

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      /* Label the data */
      content: attr(data-column);

      color: #000;
      font-weight: bold;
    }

  }

  select {
    padding: 7px 10px;
    min-width: 150px;
  }



}

.customInput {
  font-size: 1rem;
  padding: 7px 10px;
  width: 150px;
}

.registerBtn {
  padding: 6px 35px;
  background-color: green;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  color: white;
}

#noCell {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.sidebar {
  .logo {
    background: #990000!important;
  }
}

.sidebar-wrapper {
  overflow-x: hidden;
  background-color: #990000;
}

.fullscreen {
  position: fixed;
  width: 100vw;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: red;
  z-index: 999999999999;
}

.react-swipeable-view-container {
  // will-change: unset!important;
  // transform: unset!important;
}

.tabButtonWrapper {
  display: flex;
  min-width: 150px;
  justify-content: center;
  align-items: center;
.customBadge {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  color: white;
  background-color: crimson;
}
}