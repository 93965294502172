.tableActions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
  align-items: center;
  .addPatient {
    margin-top: -40px;
    margin-right: 0px;
    background-color: rgb(1, 202, 85);
    color: white;
    font-weight: 500;
    border: none;
    padding: 8px 25px;
    border-radius: 4px;
    cursor: pointer;
  }
}