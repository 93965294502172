#wizradHeader {

  // background-color: #990000;
  #header {
    position: fixed;
    background-color: #990000;
    color: white;

    .MuiButton-label {
      a {
        font-size: 2rem !important;
      }

      font-size: 1.2rem !important;
    }

    #newRegistartion {
      margin-left: 1rem;
    }

    .listIcon {
      display: inline-block;
      height: 30px;
    }

    .clockWrapper {
      margin-left: 45px;
      font-size: 1.2rem;
      font-weight: 600;
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        margin-right: 10px;
        margin-top: 0px;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .languagePicker {
      cursor: pointer;

      * {
        font-size: 1.3rem;
        color: white !important;
        font-weight: 600;
      }

      color: white !important;
      display: flex;
      justify-content: center;
      align-items: center;

      .iconWrapper {
        margin-left: 15px;
        margin-top: -8px;
      }
    }
  }
}