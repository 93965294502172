#phoneInputOuterWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: white;

  #phoneInputInnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 640px) {
      width: 80vw;
    }

    h3 {
      color: black;

      @media only screen and (max-width: 640px) {
        font-size: 24px;
      }
    }

    p {
      margin-top: 3rem;
      width: 100%;
      text-align: start;
      color: red;
    }

    .inputWrapper {
      margin-top: 2rem;
      width: 100%;
      height: 100px;

      @media only screen and (max-width: 640px) {
        height: 60px;
      }

      border: 1px solid black;
      // border-bottom: 2px solid black;

      cursor: text;
      box-shadow: 0px 55px 105px rgba(0, 0, 0, 0.082);

      input {
        text-align: center;
        width: 100%;
        height: 100%;
        border: none;
        font-size: 2.7rem;
        padding-left: 2rem;

        @media only screen and (max-width: 640px) {
          font-size: 2rem;
          padding-left: 1rem;
        }
      }
    }
  }

  .nextBtn {
    margin-top: 5rem;
    font-size: 1.3rem;
    width: 400px;

    @media only screen and (max-width: 640px) {
      width: 300px;
    }
  }

  .noPhoneBtn {
    margin-top: 1rem;
  }

}

.formError {
  margin-bottom: -3rem!important;
  margin-top: 1.5rem!important;
  max-width: 600px;
  text-align: center !important;
  transition: 0.4s;
  font-size: 1.5rem;
}