.dateRangerPickerWrapper {
  transition: 0.4s !important;

  .react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid gray;
    padding: 3px 20px;
    border-radius: 15px;
    background: white;
  }

}
