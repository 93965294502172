.patientVisitDetailsTable {
  font-size: 0.7rem!important;
  .tableHeaderRow {
    border-right: 4px solid rgb(0, 0, 0);
    display: flex;
    align-items: center;
    background-color: black;
    .headerCell {
      display: flex;
      color: white;
      border-right: 1px solid white;
      justify-content: center;
      font-weight: 600;
      padding: 0 15px;
      text-align: center;
      align-items: center;
      flex: 1;
      &:last-child {
        border-right: none;
      }
    }
  }
  .tableBody {
    max-height: 30vh;
    // padding-bottom: 50px;
    overflow-y: scroll;
    .tableBodyRow {
      display: flex;
      align-items: center;
      background-color: rgb(255, 255, 255);
      &:nth-child(even) {
        background-color: rgb(223, 223, 223);
      }
      &:hover {
        transition: 0.1s;
        background-color: rgb(0, 150, 243);
        cursor: pointer;
        .bodyCell {
          color: white!important;
        }
      }
      .bodyCell {
        display: flex;
        color: rgb(12, 12, 12);
        border-right: 1px solid rgba(149, 142, 142, 0);
        justify-content: center;
        word-break: break-all;
        font-weight: 600;
        padding: 0 15px;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
      }
    }
    // padding-right: 2px;
    &::-webkit-scrollbar {
      width: 7px!important;
      height: 0.5em;
     }
   
     &::-webkit-scrollbar-thumb {
      background-color: rgba(255,255,255,.1);
      border-radius: 3px;
   
      &:hover {
       background: rgba(255,255,255,.2);
      }
     }
  }
  .f1 {
    flex: 1;
  }
  .f2 {
    flex: 2!important;
  }
  .f3 {
    flex: 3;
  }
  .f4 {
    flex: 4;
  }
  .f5 {
    flex: 5;
  }
  .sumRow {
    display: flex;
    align-items: center;
    background-color: rgb(184, 184, 184);
    .sumCell {
      display: flex;
      color: rgb(255, 255, 255);
      border-right: 1px solid rgba(149, 142, 142, 0);
      justify-content: center;
      font-weight: 600;
      padding: 0 15px;
      font-size: 1rem;
      align-items: center;
      flex: 1;
    }
    .totalCell {
      flex: 42.1;
      justify-content: flex-start;
    }
    .valueCell {
      flex: 3.7;
      justify-content: center;
      &.lastCell {
        flex: 16;
      }
    }

  }
}