.dashboardPageWrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .dashboardSidebarWarpper {
    width: 300px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

  }

  .mainArea {
    width: calc(100% - 300px);
    display: flex;
    justify-content: flex-start;
    min-height: 100vh;
    flex-direction: column;

    .actionBar {
      display: flex;
      justify-content: flex-end;
      padding-right: 3rem;
    }

    .statsWarpper {
      width: 100%;
      // min-height: 100vh;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  .initialLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    font-size: 1.2rem;
  }
}

.dashboardLoaderWrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardTabWrapper {
  .tabsSelectorWrapper {
    margin-top: -2.3rem;
    margin-bottom: 4rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .tabsSelector {
      min-width: 300px;
      width: 30vw;
      height: 40px;
      border-radius: 100px;
      background-color: rgb(226, 226, 226);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .tab {
        font-size: 0.8rem;
        height: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        &.active {
          background-color: rgb(35, 35, 35);
          border-radius: 100px;
          cursor: unset;
          box-shadow: 2px 2px 6px rgba(0,0,0,0.12);
          font-size: 0.9rem;
          font-weight: 600;
          color: white;
        }
      }
    }
  }
}