  #languageSelectorPage {
    padding: 4rem 2rem;
    width: 100vw;
    min-height: 100vh;
    background-color: white;
    margin-top: 3rem;

    .languageSelector {
      width: 100%;
      margin-bottom: 1.5rem;

      * {
        font-size: 2rem !important;
      }

      background-color: rgb(233, 233, 233);
      // border: 2px solid rgb(192, 192, 192);
      border-radius: 8px;
      color: rgb(73, 73, 73);

      &.selected {
        background-color: rgb(11, 137, 255);
        color: white;
      }
    }

    .header {
      width: 100%;
      text-align: center;
      margin-bottom: 4rem;
    }

    .noteText {
      margin-top: 2rem;
    }
  }