#appointmentSelectorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  .titleWrapper {
    width: 100%;
    text-align: center;
    font-size: 3rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
  }
  .typeOptionsWrapper {
    width: 90vw;
    .languageSelector {
      width: 100%;
      margin-bottom: 1.5rem;
  
      * {
        font-size: 2rem !important;
      }
  
      background-color: rgb(233, 233, 233);
      // border: 2px solid rgb(192, 192, 192);
      border-radius: 8px;
      color: rgb(73, 73, 73);
  
      &.selected {
        background-color: rgb(11, 137, 255);
        color: white;
      }
    }
  
  }

  #personalData {
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 45vw;

    @media only screen and (max-width: 640px) {
      width: 80vw;
    }

    h3 {
      color: black;

      @media only screen and (max-width: 640px) {
        font-size: 24px;
      }
    }

    p {
      margin-top: 3rem;
      width: 100%;
      text-align: start;
      color: red;
      font-size: 1.2rem;
    }
  }

  .nextBtn {
    margin-top: 2rem;
    font-size: 1.3rem;
    width: 400px;

    @media only screen and (max-width: 640px) {
      width: 300px;
    }
  }

  .prevBtn {
    position: absolute;
    margin-top: 1.5rem;
    font-size: 1rem;
    width: 180px;
    top: 75px;
    left: 25px;
    
    @media only screen and (max-width: 768px) {
      width: 150px;
    }
    @media only screen and (max-width: 640px) {
      width: 180px;
    }

  }

  .userInput {
    font-size: 2rem;
  }

  .MuiInputBase-input {
    height: 60px !important;
    font-size: 30px !important;
    padding-top: 45px;
  }

  .MuiInputLabel-formControl {
    top: 10px;
    color: rgb(36, 36, 36) !important;
    font-size: 29px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
    letter-spacing: unset;
  }

  .requried {
    color: red;
  }

  .datePickerWrapper {
    margin-top: 2rem;
  }

  .visitReasonWrapperCheckin {
    max-height: 60vh;
    overflow-y: scroll;
    padding-bottom: 5rem;
  }


}

#menu-simpleSelect {
  li {
    font-size: 1.6rem !important;
  }
}