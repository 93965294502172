.stats {
  padding: 3% 3% 5% 0;
  width: 33%;
  // background-color: violet;
  height: 16%;

  .statsHeader {
    background-color: rgb(160, 0, 0);
    color: rgb(239, 255, 11);
    text-align: center;
    font-weight: 600;
    font-size: 0.9rem;

    &.secondary {
      background-color: rgb(26, 0, 0);
      color: rgb(255, 255, 255);
    }
  }

  .statsValue {
    margin-top: 2rem;
    text-align: center;
    font-weight: 400;
    font-size: 2rem;

    &.largeText {
      margin-top: 2.5rem;
      font-size: 2.6rem;
    }
  }

  .statsSubLabel {
    margin-top: 0.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 0.8rem;
  }
}