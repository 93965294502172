#languagePrompt {
  padding: 2rem 1.5rem;
  .languageSelector {
    width: 100%;
    margin-bottom: 1.5rem;

    * {
      font-size: 2rem !important;
    }

    background-color: rgb(233, 233, 233);
    // border: 2px solid rgb(192, 192, 192);
    border-radius: 8px;
    color: rgb(73, 73, 73);

    &.selected {
      background-color: rgb(11, 137, 255);
      color: white;
    }
  }

  .header {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
  .actionWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    button {
      display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(212, 41, 41);
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 1.5rem;
    padding: 15px 55px;  
    }
  }
}