#tvTablesWrapper {
  background-color: white;
  width: 100vw;
  height: 100vh;

  .redRow {
    background-color: red!important;
    color: white;
  }

  .yellowRow {
    background-color: rgb(255, 230, 0)!important;
    color: rgb(58, 58, 58);
  }

  .tvHeader {
    padding: 35px 100px 30px 100px;
    font-size: 3rem;
    text-align: center;
    background-color: #990000;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    .clockWrapper {
      font-size: 2.5rem;
      font-weight: 400;
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        margin-right: 25px;
        margin-top: -10px;
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .tableWrapper {
    height: 100%;
    padding: 0rem 2rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 25px;

    .table {
      &:first-child {
        flex: 10;
      }

      &:last-child {
        flex: 13;
      }

      .tableHeader {
        font-size: 45px;
        font-weight: 400;
        color: black;
        text-align: center;
        width: 100%;
        margin: 25px;
      }

      // height: 100px;
      width: 100%;
      // max-height: 40%;

      margin-bottom: 45px;

      // margin-top: 1rem;
      // background-color: yellow;
      table {
        width: 100%;

        @media only screen and (max-width: 768px) {
          width: 700px;
        }

        border-collapse: collapse;
        margin: 0px auto;
        // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.116);
        border-radius: 5px;
      }

      /* Zebra striping */
      tr:nth-of-type(odd) {
        background: rgb(230, 230, 230);
      }

      th {
        background: #292929;
        color: white;
        font-weight: bold;
      }

      td,
      th {
        padding: 10px;
        // border-bottom: 1px solid rgb(22, 22, 22);
        text-align: left;
        font-size: 35px;
        font-weight: 400;
        padding: 25px;
      }

      /* 
      Max width before this PARTICULAR table gets nasty
      This query will take effect for any screen smaller than 760px
      and also iPads specifically.
      */
      @media only screen and (max-width: 760px) {

        table {
          width: 100%;
        }

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          border: 1px solid #ccc;
        }

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
        }

        td:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
          /* Label the data */
          content: attr(data-column);

          color: #000;
          font-weight: bold;
        }

      }

      tr>td {
        font-size: 35px;
        font-weight: 400;
        padding: 25px;
      }

      select {
        padding: 7px 10px;
        min-width: 150px;
      }
    }
  }
}