#extendedActionsWrapper {
  height: 650px;

  .modalHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .dismissBtn {
      background-color: rgb(250, 44, 44);
      border: none;
      border-bottom-left-radius: 8px;
      color: white;
      font-size: 0.9rem;
      padding: 4px 35px;
      cursor: pointer;
    }
  }

  .modalTitleWrapper {
    margin-top: 20px;
    padding: 15px 40px;

    p {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 2.1rem;
      span {
        font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      }
    }

    .callNumber {
      font-size: 1.5rem;
      font-weight: 400;
    }

    .actionsLabel {
      font-size: 1.2rem;
      margin-top: 35px;
      width: 100%;
      text-align: center;
    }
  }

  position: relative;
  height: 100%;

  .actionsWrapper {
    margin-top: -20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modalActionBtn {
      // flex: 1;
      height: 50px;
      width: 90%;
      font-weight: 600;
      border: 1px solid black;
      background-color: rgb(17, 73, 255);
      color: white;
      font-size: 1.1rem;
      cursor: pointer;
      display: flex;
      margin: 5px 10px;
      border-radius: 36px;
      justify-content: center;
      align-items: center;
    }

  }
}