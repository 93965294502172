#bottomLoaderWrapper {
  width: 100vw;
  height: 70px;
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  bottom: 10px;
  z-index: 150990;
  display: flex;
  justify-content: center;
  align-items: center;
  .bottomLoader {
    width: 400px;
    height: 60px;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.596);
    display: flex;
    // justify-content: center;
    align-items: center;
    .spinnerWrapper {
      flex: 4;
      display: flex;
      justify-content: flex-end;
      padding-right: 5px;
      align-items: center;
      .loader {
        border: 3px solid #5a5a5a;
        border-radius: 50%;
        border-top: 3px solid #ffffff;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 0.7s linear infinite; /* Safari */
        animation: spin 0.7s linear infinite;
      }
    }
    p {
      padding: 13px 0px 0px 25px;
      font-weight: 600;
      font-size: 1.2rem;
      color: rgb(255, 255, 255);
      flex: 8
    }
    
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}