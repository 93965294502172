#accountClinicSelectorWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: relative;

  h3 {
    text-align: center;
  }

  .clinics {
    width: 100%;
    margin-top: 2rem;
    // border: 1px solid rgb(167, 167, 167);
    border-top: 1px solid rgb(202, 202, 202);

    .clinic {
      background-color: rgba(226, 226, 226, 0.5);
      // margin: 1rem 0;
      height: 50px;
      font-weight: 600;
      border-bottom: 1px solid rgb(202, 202, 202);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 3.8rem;
      width: 100%;
      color: #990000;
      cursor: pointer;

      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #990000;
        color: white;
        width: 34px;
        height: 34px;
        border-radius: 100px;
      }
    }
  }

  .logout {
    position: absolute;
    bottom: 35px;
    color: rgb(88, 88, 88);
    font-weight: 400;
    cursor: pointer;

  }
}