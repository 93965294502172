#promtWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    margin-top: 2.6rem;
  }

  .actionWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.7rem;
    .confirm {
      background-color: rgb(24, 24, 24);
      border: none;
      color: white;
      padding: 14px 40px;
      border-radius: 30px;
      font-size: 1.5rem;
      cursor: pointer;
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.596);
      margin-right: 0rem;
    }
    .cancel {
      background-color: white;
      border: none;
      color: red;
      padding: 14px 20px;
      border-radius: 30px;
      font-size: 1.5rem;
      cursor: pointer;
      margin-left: 2rem;
    }
  }
}